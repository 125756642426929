import problemSettingBackground from '@/assets/images/home/problem-setting.svg'
import TwoColumnLayout from '@/components/pages/home/TwoColumnLayout'
import { ContainerFullWidth } from '@/components/styled/Container'
import { H2 } from '@/components/styled/Typography'
import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled(ContainerFullWidth)`
  background-color: #170e94;
`

const Heading = styled(H2)`
  margin-top: 0;
  color: white;
  margin-bottom: 0.5rem;
`

const Element = styled.div`
  padding-bottom: 1rem;
  border-bottom: 1px solid white;
  color: white;
`

const ElementHeading = styled.h3`
  margin: 0;
  margin-top: 1.5rem;
`

const Content = styled.p`
  margin: 1rem 0 1rem 0;
  max-width: 100%;
`

const items = [
  {
    id: 0,
    heading: '課題１',
    content: '日本の文化を体験したいが欲しい情報にアクセスできない外国人'
  },
  {
    id: 1,
    heading: '課題２',
    content: '海外販路を開拓できない地方の老舗和菓子メーカー'
  },
  {
    id: 2,
    heading: 'ソリューション',
    content:
      '海外に流通しにくい期間限定・地域限定商品をICHIGOがキュレーションしてグローバルマーケットへ提供する'
  }
]

export default function ProblemSetting() {
  return (
    <Wrapper>
      <TwoColumnLayout imageSrc={problemSettingBackground} imageFirst>
        <Heading>ICHIGO Inc.が成し遂げたいこと</Heading>
        {items.map(({ id, heading, content }) => (
          <Element key={id}>
            <ElementHeading>{heading}</ElementHeading>
            <Content>{content}</Content>
          </Element>
        ))}
      </TwoColumnLayout>
    </Wrapper>
  )
}
