import companyImage from '@/assets/images/home/company.png'
import { H2 } from '@/components/styled/Typography'
import { bodyFont, breakpoint } from '@/utils/styles'
import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint} {
    flex-direction: row;
  }
`

const Text = styled.div`
  flex: 0 0 50%;
  padding: 0.5rem;

  ${breakpoint} {
    padding: 2rem;
  }
`

const Title = styled(H2)``

const Attributes = styled.ul`
  padding: 0;
  border: 1px solid #a9a7c3;
`

const Attribute = styled.li`
  display: block;
  list-item-type: none;
  padding: 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid #a9a7c3;
  }
`

const AttributeTitle = styled.h3`
  margin: 0;
  font-family: ${bodyFont};
  font-weight: normal;
  color: #726f90;
  text-transform: uppercase;
  font-size: 20px;
`

const AttributeContent = styled.p`
  margin: 0;
  font-size: 20px;
`

const Image = styled.img`
  margin-bottom: 2rem;
  margin-left: -2rem;
  margin-right: -2rem;
  width: calc(100% + 8rem);

  ${breakpoint} {
    flex: 0 0 50%;
    max-width: 50%;
    align-self: flex-start;
    margin-top: 8rem;
    margin-left: 2rem;
    margin-bottom: 0;
  }
`

export default function Company() {
  return (
    <Wrapper>
      <Text>
        <Title>The Company</Title>
        <Attributes>
          <Attribute>
            <AttributeTitle>会社名</AttributeTitle>
            <AttributeContent>
              株式会社ICHIGO（英語表記：ICHIGO Inc.）
            </AttributeContent>
          </Attribute>
          <Attribute>
            <AttributeTitle>本店所在地</AttributeTitle>
            <AttributeContent>
              東京都港区芝五丁目１３番１８号いちご三田ビル７階
            </AttributeContent>
          </Attribute>
          <Attribute>
            <AttributeTitle>事業内容</AttributeTitle>
            <AttributeContent>
              D2C越境ECサブスクリプションサービス
              <br />
              オンラインクレーンゲーム
              <br />
              ソーシャルコマース
            </AttributeContent>
          </Attribute>
          <Attribute>
            <AttributeTitle>設立</AttributeTitle>
            <AttributeContent>2015年8月3日</AttributeContent>
          </Attribute>
          <Attribute>
            <AttributeTitle>役員</AttributeTitle>
            <AttributeContent>
              代表取締役CEO 近本あゆみ
              <br />
              代表取締役CTO デビッドアシキン
            </AttributeContent>
          </Attribute>
          <Attribute>
            <AttributeTitle>資本金</AttributeTitle>
            <AttributeContent>1000万円（2021年10月1日現在）</AttributeContent>
          </Attribute>
          <Attribute>
            <AttributeTitle>主要取引銀行</AttributeTitle>
            <AttributeContent>
              三井住友銀行、きらぼし銀行、りそな銀行、みずほ銀行、PayPay銀行
            </AttributeContent>
          </Attribute>
        </Attributes>
      </Text>
      <Image src={companyImage} />
    </Wrapper>
  )
}
