import { breakpoint } from '@/utils/styles'
import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

const TwoColumnWrapper = styled.div`
  display: grid;

  ${breakpoint} {
    grid-template-columns: 1fr 1fr;
  }
`

const TwoColumnImage = styled.img<{
  imageFirst: boolean
  hideImageOnMobile: boolean
}>`
  ${({ hideImageOnMobile }) => (hideImageOnMobile ? 'display: none;' : '')};
  max-width: 100%;

  ${breakpoint} {
    display: block;
    order: ${({ imageFirst }) => (imageFirst ? '1' : '2')};
  }
`

const TwoColumnContent = styled.div<{ imageFirst: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 22px;
  padding: 2rem 1rem;

  ${breakpoint} {
    padding: 4rem;
    order: ${({ imageFirst }) => (imageFirst ? '2' : '1')};
  }
`

export default function TwoColumnLayout({
  children,
  imageSrc,
  imageFirst,
  hideImageOnMobile
}: {
  children: ReactNode
  imageSrc: string
  imageFirst?: boolean
  hideImageOnMobile?: boolean
}) {
  return (
    <TwoColumnWrapper>
      <TwoColumnImage
        src={imageSrc}
        imageFirst={imageFirst}
        hideImageOnMobile={hideImageOnMobile}
      />
      <TwoColumnContent imageFirst={imageFirst}>{children}</TwoColumnContent>
    </TwoColumnWrapper>
  )
}

TwoColumnLayout.defaultProps = {
  imageFirst: false,
  hideImageOnMobile: false
}
