import arrowRight from '@/assets/images/services/arrow-right.svg'
import { NewsCard, PrismicAllNews } from '@/components/generic/NewsCard'
import ButtonBlueOutline from '@/components/styled/ButtonBlueOutline'
import { H2 } from '@/components/styled/Typography'
import { NewsWrapper, PageWrapper } from '@/pages/news'
import { formatDateToJA } from '@/utils/dates/formatDateToJA'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

const Heading = styled(H2)`
  margin-bottom: 3rem;
  color: #020247;
`

const BluePageWrapper = styled(PageWrapper)`
  align-items: flex-start;
  padding: 4rem 0;
  background-color: #fafaff;
`

const NewsPageLink = styled(Link)`
  text-decoration: none;
  align-self: center;
`

const ArrowRight = styled.img`
  margin-left: 1rem;
`

export default function LatestNews({ data }: { data: PrismicAllNews }) {
  return (
    <BluePageWrapper>
      <Heading>Latest News</Heading>
      <NewsWrapper>
        {data.allPrismicNew.edges.map(({ node }) => (
          <NewsCard
            heading={node.data.heading.text}
            subheading={node.data.subheading.text}
            date={formatDateToJA(node.data.date)}
            excerpt={node.data.excerpt.text}
            link={node.data.link.url}
          />
        ))}
      </NewsWrapper>
      <NewsPageLink to="/news">
        <ButtonBlueOutline>
          See More News
          <ArrowRight src={arrowRight} alt="" />
        </ButtonBlueOutline>
      </NewsPageLink>
    </BluePageWrapper>
  )
}
