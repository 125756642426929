import heroBackground from '@/assets/images/home/hero-background.png'
import heroOverlay from '@/assets/images/home/hero-overlay.png'
import logo from '@/assets/images/logo-home.svg'
import Header from '@/components/layouts/Header'
import { Container } from '@/components/styled/Container'
import { breakpoint } from '@/utils/styles'
import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #170e94;
  background-image: url(${heroBackground});
  background-repeat: repeat;
  background-size: 200vw;

  ${breakpoint} {
    background-size: cover;
    background-position: center;
  }
`

const HeroWrapper = styled(Container)`
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoint} {
    flex-direction: row;
  }
`

const HeroOverlay = styled.img`
  max-width: 100%;
  margin-top: -1rem;

  ${breakpoint} {
    max-width: 70%;
    margin-top: -16rem;
    margin-left: -4rem;
  }
`

const Logo = styled.img`
  max-width: 60%;
  margin-bottom: 5rem;

  ${breakpoint} {
    max-width: 25%;
    margin-top: -14rem;
    margin-left: 1rem;
    margin-bottom: 0;
  }
`

export default function Hero() {
  return (
    <Wrapper>
      <Header hasLogo={false} inverse />
      <HeroWrapper>
        <HeroOverlay src={heroOverlay} alt="Images of Ichigo products" />
        <Logo src={logo} alt="Ichigo logo" />
      </HeroWrapper>
    </Wrapper>
  )
}
