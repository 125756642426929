import missionImage from '@/assets/images/home/mission.png'
import visionImage from '@/assets/images/home/vision.png'
import { PrismicAllNews } from '@/components/generic/NewsCard'
import Layout from '@/components/layouts/Layout'
import Company from '@/components/pages/home/Company'
import Hero from '@/components/pages/home/Hero'
import LatestNews from '@/components/pages/home/LatestNews'
import ProblemSetting from '@/components/pages/home/ProblemSetting'
import TwoColumnLayout from '@/components/pages/home/TwoColumnLayout'
import { Container } from '@/components/styled/Container'
import { H2 } from '@/components/styled/Typography'
import { breakpoint } from '@/utils/styles'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React from 'react'

const ContentTitle = styled(H2)`
  margin: 0 0 0.5rem 0;
`

const ContentSubtitle = styled.p`
  font-weight: bold;
  margin: 0 0 1rem 0;
`

const ContentList = styled.ul`
  color: #726f90;
`

const ContentListItem = styled.li`
  margin-bottom: 0.5rem;
`

const ContentParagraph = styled.p`
  color: #726f90;
`

const CustomContainer = styled(Container)`
  padding: 0;

  ${breakpoint} {
    padding: 2;
  }
`

export default function Home({ data }: { data: PrismicAllNews }) {
  return (
    <Layout header={<Hero />} LayoutContainer={CustomContainer}>
      <TwoColumnLayout imageSrc={missionImage} hideImageOnMobile>
        <ContentTitle style={{ color: `#1E14AF` }}>Mission</ContentTitle>
        <ContentSubtitle>世界中をJAPANにする</ContentSubtitle>
        <ContentList>
          <ContentListItem>
            海外にいる人が日本で生活しているかのような「臨場感のある」サービスを届ける。
          </ContentListItem>
          <ContentListItem>
            世界中に日本の文化をインフラのように広める。ICHIGOが世界中のコンビニになる。
          </ContentListItem>
          <ContentListItem>
            日本の小さなメーカーが世界ブランドになるようにサポートする。
          </ContentListItem>
        </ContentList>
      </TwoColumnLayout>
      <TwoColumnLayout imageSrc={visionImage} imageFirst>
        <ContentTitle style={{ color: `#FF0030` }}>Vision</ContentTitle>
        <ContentParagraph>
          日本の文化を通じて世界中の人を繋げ、常に日本の文化を体験したり日本が好きな人たち同士で語り合ったりすることがスタンダードな世の中にする
        </ContentParagraph>
      </TwoColumnLayout>
      <ProblemSetting />
      <Container>
        <LatestNews data={data} />
      </Container>
      <Container>
        <Company />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allPrismicNew(
      limit: 3
      sort: { fields: [data___article_date], order: DESC }
    ) {
      edges {
        node {
          data {
            heading {
              text
            }
            subheading {
              text
            }
            date
            excerpt {
              text
            }
            link {
              url
            }
          }
        }
      }
    }
  }
`
